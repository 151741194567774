import React from 'react';

import {graphql} from 'gatsby';
import {Box, LayoutGrid, StyledRichContent, SEOWithQuery} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';

import {CommunityGuidelinesPageQuery} from 'graphql-types';

const {P0, P2} = Typography;

export const query = graphql`
  query CommunityGuidelinesPage {
    doc: sanityPublicCommunityGuidelines(_id: {eq: "publicCommunityGuidelines"}) {
      lastUpdatedDate(formatString: "MMM D, YYYY")
      content: _rawContent
      copyright
    }
  }
`;

type CommunityGuidelinesPageProps = {
  data: CommunityGuidelinesPageQuery;
};

const CommunityGuidelinesPage: React.FC<CommunityGuidelinesPageProps> = ({data}) => {
  return (
    <Box backgroundColor="darkGreen">
      <LayoutGrid>
        <Box gridColumn="1/end">
          <Box textAlign="center" pt="83px" pb="43px" mb="32px">
            <Box width="150px" mb="34px" mx="auto">
              <Icon aria-label={copy.communityGuidelines.ariaLabels.logo} icon={Icons.logo} color="cream" />
            </Box>
            <P0 color="cream" mb="21px">
              {copy.communityGuidelines.title}
            </P0>
            {data.doc?.lastUpdatedDate && (
              <P0 color="cream" mb="21px">
                {copy.communityGuidelines.lastUpdatedLabel} {data.doc.lastUpdatedDate}
              </P0>
            )}
          </Box>
          <StyledRichContent blocks={data.doc?.content} />
          <Box as="hr" my="24px" />
          {data.doc?.copyright && (
            <P2 color="cream" textAlign="center" mb="40px">
              {data.doc.copyright}
            </P2>
          )}
        </Box>
      </LayoutGrid>
      <SEOWithQuery title={copy.seo.communityGuidelinesTitle} noIndex />
    </Box>
  );
};

export default CommunityGuidelinesPage;
